import Layout from "pages/Auth-Page/Components/Layout";
import LoginPage from "pages/Auth-Page/Components/Login";
import LogoutPage from "pages/Auth-Page/Components/Logout";
import PasswordConfirm from "pages/Auth-Page/Components/PasswordConfirm";
import PasswordReset from "pages/Auth-Page/Components/PasswordReset";
import RequireAuth from "pages/Auth-Page/Components/RequireAuth";
import SignUpPage from "pages/Auth-Page/Components/SignUp";
import ThanksPage from "pages/Auth-Page/Components/Thanks";

import BinDashboard from "pages/Bin-Dashboard/BinDashboard";
import MainDashboard from "pages/Main-Dashboard/MainDashboard";
import Profile from "pages/Profile/Profile";
import WasteDashboard from "pages/Waste-Dashboard/WasteDashboard";
import Savings from "pages/CO2-Savings/Savings";
import SubclassDisplay from "pages/Subclass-Display/SubclassDisplay";
import { Route, Routes, useLocation } from "react-router-dom";
import { LanguageContext } from "context/LanguageContext";

import HttpsRedirect from "react-https-redirect";
import React, { useState, useEffect } from "react";
import ChangePassword from "pages/Change-Password/ChangePassword";
import GHGDashboard from "pages/GHG-Dashboard/GHGDashboard";
import WeightScale from "pages/WeightScale/WeightScale";


function App() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  return (
    <HttpsRedirect>
      <div className="App">
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/thanks" element={<ThanksPage />} />

              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/password/email" element={<PasswordReset />} />
              <Route path="/password/confirm" element={<PasswordConfirm />} />

              <Route element={<RequireAuth />}>
                <Route path="/" element={<MainDashboard />} />
                <Route path="/bin-dashboard" element={<BinDashboard />} />
                <Route
                  path="/waste-dashboard/:id/:wasteitem"
                  element={<WasteDashboard />}
                />
                <Route path="/profile" element={<Profile />} />
                <Route path="/Savings/:id/:wasteitem" element={<Savings />} />
                <Route path="SubclassDisplay/:id/" element={<SubclassDisplay />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/ghgdashboard/:id/" element={<GHGDashboard />} />
                <Route path="/weightscale" element={<WeightScale />} />
              </Route>
            </Route>
          </Routes>
        </LanguageContext.Provider>
      </div>
    </HttpsRedirect>
  );
}

export default App;
