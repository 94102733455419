import residual from "Assets/svg/residual.svg";
import Button from "common/Components/Button/Button";
import Navbar from "common/Components/NavBar/NavBar";
import Sidebar from "common/Components/SideBar/SideBar";
import React, { useState, useEffect } from "react";
import axiosInstance from "api/axios";
import { useLocation,useNavigate } from "react-router-dom";
import WasteItems from "./Components/Waste-Items/Waste-Items";
import WasteLevelsChart from "./Components/Waste-Levels-Chart/Waste-Levels-Chart";
import "../Waste-Dashboard/WasteDashboard.css";
import Hamburger from "common/Components/Hamburger/Hamburger";
import { MdArrowDropDown } from "react-icons/md";
import {AiOutlineArrowDown} from 'react-icons/ai'
import {AiOutlineArrowUp} from 'react-icons/ai'
import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import {BsImages} from 'react-icons/bs'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ImageDisplay from "./Components/Image-Display/ImageDisplay";


const WasteDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const wasteitem = pathname.split("/")[3];
  const id = pathname.split("/")[2];
  const [selectedValue, setSelectedValue] = useState("24hrs");
  const [dayvalue, setDayValue] = useState("");
  const [selectedButton, setSelectedButton] = useState('day');
  const [binIds, setBinIds] = useState([]);
  const [selectedstream, setSelectedStream] = useState("organic");
  const [currentWasteItem, setCurrentWasteItem] = useState(wasteitem);
  const [currentbinid, setCurrentBinId] = useState(id);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalwasteproduced, setTotalWasteProduced] = useState()
  const [colorwaste, setColorWaste] = useState()
  const [percentagechange, setPercentageChange] = useState()
  const { language } = useContext(LanguageContext);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const[bin,setBin] = useState()
  const[link, setLink] = useState('none')
  const [tier, setTier] = useState('')
  const [TotalWasteWeight, setTotalWasteWeight]  = useState('')


  const handleClick = (btnvalue) => {
    setDayValue(btnvalue);
    setSelectedButton(btnvalue);
  };

  useEffect(() => {
    axiosInstance
      .get('/get_bins')
      .then((response) => {
        console.log(response.data);
        setBinIds(response.data.map((item) => item.unique_identifier));
        const foundData = response.data.find(item => item.unique_identifier === id);
        setBin(foundData);
        if(foundData.image_google_drive_link !== "none"){
          setLink(foundData.image_google_drive_link)
        }

      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    axiosInstance
        .get("/usertier")
        .then((response) => {
            console.log(response.data.tier)
            setTier(response.data.tier)
        })
        .catch((error) => {
        console.error(error);
        });
    }, []);

  useEffect(() => {
    axiosInstance
      .get("/totalwasteproduced", {
        params: {
          wasteitem: currentWasteItem,
          id: id,
          day: dayvalue,
        },
      })
      .then((response) => {
        console.log(response.data);
        setPercentageChange(parseFloat(response.data.percentage_change).toFixed(2))
        setColorWaste(response.data.color)
        setTotalWasteProduced(response.data.total_waste)
        setTotalWasteWeight(response.data.waste_weight)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dayvalue, wasteitem, id]);
  

    const handleDropdownChange = (event) => {
      const newSelectedStream = event.target.value;
      setSelectedStream(newSelectedStream);
      setCurrentWasteItem(newSelectedStream);
      navigate(`/waste-dashboard/${currentbinid}/${newSelectedStream}`);
    };

    const handleBinDropDownChange = (event) =>{
      const newSelectedBin = event.target.value;
      setCurrentBinId(newSelectedBin);
      navigate(`/waste-dashboard/${newSelectedBin}/${currentWasteItem}`);
    }

    const handleBinChange = (event) =>{
      const newlySelectedBin = event.target.value;
      setCurrentBinId(newlySelectedBin)
      navigate(`/waste-dashboard/${newlySelectedBin}/${currentWasteItem}`)
    }

    const translations = {
      day: 'dag',
      week: 'week',
      month: 'maand',
      '3months': '3 maanden',
      '6months': '6 maanden',
      year: 'jaar'
    };
    const translatedSelectedButton = language === "Dutch" ? translations[selectedButton] : selectedButton;
    const message = `Totaal Afval in Vergelijking Met Vorige ${translatedSelectedButton}`;

    const handleImageClick = ()=>{
      if(link!== "none"){
        setIsButtonClicked(!isButtonClicked);
      }
    }


  return (
    <div
      style={{ minHeight: "100vh" }}
      class="bg-white dark:bg-plaex-dark dark:text-white"
      // id="wasteDashboard"
    >
      {/* Nav and Side Bar Import */}
      <Navbar />
      {/* <Sidebar onBinClick={handleClick} /> */}
      <Hamburger onBinClick={handleClick} />
      {/* Buttons for filtering waste by duration */}
      <div className="h-full ml-16 mt-24 mb-10 md:ml-64 p-4 lg:ml-64 xs:ml-0 sm:ml-0">
      <div className="Waste-Filter-Container">
        <div className="Waste-Filter-Container-title sm:flex-col xs:flex-col lg:flex-row md:flex-row text-xl sm:-mt-4 xs:-mt-4 lg:mt-0 md:mt-0 w-full lg:justify-start xs:justify-center sm:justify-center md:justify-start items-center capitalize flex flex-row gap-x-2 sm:gap-y-3 xs:gap-y-3">
          <div className="flex flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col space-3">
          <span className="text-xl font-semibold xs:text-center">
            {language === "Dutch"
            ? selectedstream === "organic"
              ? "Biologisch"
              : selectedstream === "PMD"
              ? "PMD"
              : selectedstream === "paper"
              ? "Papier"
              : selectedstream === "residual"
              ? "Restafval"
              : selectedstream
            : wasteitem}

              </span>

              <div className="flex space-x-3 ml-2">
                <div
                className="capitalize bg-plaex-light-green flex flex-row p-1 text-center items-center justify-center rounded-lg"
              >
                <img src={residual} className="h-6 w-6"/>
                {/* <span className="text-[18px]">{currentbinid}</span> */}
                <select className="select bg-plaex-light-green text-sm" size="1" onChange={handleBinDropDownChange}>
                  {binIds.map((binId) => (
                    <option key={binId} value={binId}>
                      {binId}
                    </option>
                  ))}
                    {binIds.length > 0 && <option value="all">All</option>}
                </select>
              </div>
              
              <select onChange={handleDropdownChange} className="border-2 xs:w-24  xs:h-8 border-plaex-bar flex items-center justify-center rounded-lg text-sm lg:ml-auto md:ml-0 sm:ml-0 xs:ml-0">
                  <option value="organic">{language === "Dutch" ? "Biologisch": "Organic"}</option>
                  <option value="PMD">{language === "Dutch" ? "PMD" : "PMD"}</option>
                  <option value="paper">{language === "Dutch" ? "Papier" :"Paper"}</option>
                  <option value="residual">{language === "Dutch" ? "Restafval" : "Residual"}</option>
              </select>

              </div>

          </div>
        </div>
        <div className="Filter-Buttons grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-3 lg:w-auto md:w-auto xs:w-full sm:w-full sm:px-5 xs:px-5 lg:px-0 md:px-0">
          <Button
            text={language === "Dutch" ? "1 Dag" : "1 Day"}
            onClick={(event) => handleClick("day")}
            value="day"
            className={selectedButton === 'day' ? 'selected' : ''}
          />
          <Button
            text="1 Week"
            onClick={(event) => handleClick("week")}
            value="week"
            className={selectedButton === 'week' ? 'selected' : ''}
          />
          {/* <Button text="2 Weeks" onClick={(event)=>handleClick} /> */}
          <Button
            text={language === "Dutch" ? "1 Maand" : "1 Month"}
            onClick={(event) => handleClick("month")}
            value="month"
            className={selectedButton === 'month' ? 'selected' : ''}
          />
          <Button
            text= {language === "Dutch" ? "3 Maanden" : "3 Months"}
            onClick={(event) => handleClick("3months")}
            value="3months"
            className={selectedButton === '3months' ? 'selected' : ''}
          />
          <Button
            text={language === "Dutch" ? "6 Maanden" : "6 Months"}
            onClick={(event) => handleClick("6months")}
            value="6months"
            className={selectedButton === '6months' ? 'selected' : ''}
          />
          <Button
            text={language === "Dutch" ? "1 Jaar" : "1 Year"}
            onClick={(event) => handleClick("year")}
            value="year"
            className={selectedButton === 'year' ? 'selected' : ''}
          />
        </div>


        <div className="mt-4 flex flex-col items-center justify-center">
            <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message}` : `Total Waste compared to previous ${selectedButton}`}</span>
            <div className="flex items-center justify-center gap-4">
              <span className="font-semibold flex items-center justify-center text-base">{totalwasteproduced} &nbsp; <span className="font-light">Objects</span> &nbsp;<span className="font-light">|</span>&nbsp;{TotalWasteWeight} &nbsp; <span className="font-light">KG</span></span>{" "}
              <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste == "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
            </div>
        </div>

      </div>
      {/* Total Waste count */}
      {/* <div className="Total-Waste-Container">
        <h1 className="Total-Waste-h1">Total Waste:</h1>
        <h1 className="Number-of-Wastes">
          24,093 <span className="Waste-Increase">+123% </span>
        </h1>
      </div> */}
      {/* Waste Level Chart  */}

      <div className="Waste-Level-Chart-Container relative">
      {tier === "Business" && (<div className="flex w-56 right-0 justify-end absolute mx-10">
            <BsImages className='w-6 h-6 text-plaex-bar transition-transform z-50 duration-300 ease-in-out transform hover:scale-[0.8]' data-tooltip-id="image" data-tooltip-content="Display Waste Images" onClick={handleImageClick}/>
            <ReactTooltip id="image"/>
      </div>)}
        <WasteLevelsChart id={id} wasteitem={currentWasteItem} dayvalue={dayvalue} />
      </div>

      {/* Waste Items Table  */}
      <div className="Waste-Items-Container">
        <div className="Waste-Items-Header">
        <h1 className="Waste-Items-h1 font-semibold text-xl">{language === "Dutch" ? "Afvalartikelen":  "Waste Items"}</h1>
          
          <div className="Recently-Added">
            <div className="dropdown flex flex-col ">
              <div>
                <div className="ml-auto pr-6 ">
                  <label className= "font-semibold" for="waste">{language === "Dutch" ? "Recent Toegevoegd" : "Recently Added"}</label>
                  <br />
                  <select
                    onChange={(e) => {
                      setSelectedValue(e.target.value);
                      console.log(e.target.value);
                    }}
                    // value={tableDate}
                    className=" text-sm p-2 border dark:bg-plaex-dark rounded-md hover:text-plaex-green"
                  >
                    <option value="24hrs" selected>
                      {language === "Dutch" ? "24 Uur Geleden" : "24 Hour Ago"}
                    </option>
                    <option value="week">{language === "Dutch" ? "Een week" : "A Week"}</option>
                    <option value="month">{language === "Dutch" ?  "Een Maand Geleden ":"A Month Ago"}</option>
                    <option value="year">{language === "Dutch" ?  "Een Jaar Geleden ": "A Year Ago"}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WasteItems id={id} value={selectedValue} wastevalue={currentWasteItem} />

        {/* <div className="See-All-Container">
          See All
          <AiOutlineArrowRight className="See-All-Right-Arrow-Icon" />
        </div> */}
        {isButtonClicked &&  (<ImageDisplay link={link} />)}
      </div>
      </div>
    </div>
  );
};

export default WasteDashboard;
