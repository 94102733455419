import logo from "Assets/images/logo_white.png";
import back_video from "Assets/videos/Login_background.mp4";
import { LanguageContext } from "context/LanguageContext";
import {useContext} from 'react'
import "./Login.css";

function ThanksPage() {
  const {language} = useContext(LanguageContext);
  return (
    <div className="relative h-screen">
      <div className="absolute inset-0 z-0">
        <video className="w-full h-full object-cover" autoPlay muted loop>
          <source src={back_video} type="video/mp4" />
        </video>
      </div>
      <div className="relative z-10 flex items-center justify-center">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 flex items-center justify-center h-screen">
          <div className="mx-auto max-w-lg">
            <div className="mx-auto w-1/6 pb-5">
              <img src={logo} alt="logo" />
            </div>
            <h1 className="text-center text-xl font-bold text-green-600 sm:text-3xl">
            {language === "Dutch" ? "Aan De Slag Met Garby" : "Get started today with Garby"}
            </h1>
            <br />
            <h1 className="font-bold text-2xl text-white sm:text-3xl text-center">
             { language === "Dutch" ?  "Bedankt dat u zich bij ons hebt aangemeld, we zullen binnenkort contact met u opnemen om u onze apparaten te bieden" : "Thank you for signing up with us, we will be in contact soon to provide you with our devices"} 
            </h1>
            <h6 className="mt-8 text-green-600 text-center">
            {language === "Dutch" ? "Controleer uw Email" :  "Check your Email"}
            </h6>
            {/* Error message*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThanksPage;
