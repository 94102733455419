import residual from "Assets/svg/residual.svg";
import Button from "common/Components/Button/Button";
import Navbar from "common/Components/NavBar/NavBar";
import Sidebar from "common/Components/SideBar/SideBar";
import React, { useState, useEffect } from "react";
import axiosInstance from "api/axios";
import { useLocation,useNavigate } from "react-router-dom";
import "../Waste-Dashboard/WasteDashboard.css";
import Hamburger from "common/Components/Hamburger/Hamburger";
import CO2SavingsChart from "./Components/CO2SavingsChart"
import CostSavings from "./Components/CostSavings"
import { MdArrowDropDown } from "react-icons/md";
import WasteItems from "pages/Waste-Dashboard/Components/Waste-Items/Waste-Items";
import "../Waste-Dashboard/WasteDashboard.css"
import {AiOutlineArrowDown} from 'react-icons/ai'
import {AiOutlineArrowUp} from 'react-icons/ai'

import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import CustomCalendar from "common/Components/Calendar/Calendar";
import {AiTwotoneCalendar, AiOutlineClose} from "react-icons/ai"


const CO2Savings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const wasteitem = pathname.split("/")[3];
  const id = pathname.split("/")[2];
  const [selectedValue, setSelectedValue] = useState("24hrs");
  const [dayvalue, setDayValue] = useState("");
  const [selectedButton, setSelectedButton] = useState('day');
  const [binIds, setBinIds] = useState([]);
  const [selectedstream, setSelectedStream] = useState("all");
  const [currentWasteItem, setCurrentWasteItem] = useState(wasteitem);
  const [currentbinid, setCurrentBinId] = useState(id);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalwasteproduced, setTotalWasteProduced] = useState()
  const [totalcost, seTotalCost] = useState()
  const [colorwaste, setColorWaste] = useState()
  const [percentagechange, setPercentageChange] = useState()
  const { language } = useContext(LanguageContext);
  const [percentagechangecost, setPercentageChangecost] = useState()
  const [colorwastecost,setColorWastecost] = useState()

  const [calendarclick, setCalendarClick] = useState(false);
  const [iscustomDate, setIsCutomDate] = useState(false);
  const [datevalue, setDateValue] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);


  const resetDates = () => {
    setDateValue([
      {
        startDate: null,
        endDate: null,
        key: 'selection'
      }
    ]);
  };


  const handleCloseCalendar = () => {
    setCalendarClick(false);
  }
  const handleDateValueChange = (newDateValue) => {
    setDateValue(newDateValue);
    console.log(datevalue)
    setCalendarClick(false)
  }

  const handleCalendarClick = () =>{
    setCalendarClick(!calendarclick);
  }


  const handleClick = (btnvalue) => {
    console.log("pressed");
    setDayValue(btnvalue);
    setSelectedButton(btnvalue);
    resetDates()
  };

  useEffect(() => {
    axiosInstance
      .get('/get_bins')
      .then((response) => {
        console.log(response.data);
        setBinIds(response.data.map((item) => item.unique_identifier));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/totalco2saved", {
        params: {
          wasteitem: currentWasteItem,
          id: id,
          day: dayvalue,
        },
      })
      .then((response) => {
        console.log(response.data);
        setPercentageChange(parseFloat(response.data.percentage_change).toFixed(2))
        setColorWaste(response.data.color)
        setTotalWasteProduced(response.data.total_co2_current)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dayvalue, wasteitem, id]);
  
  useEffect(() => {
    axiosInstance
      .get("/totalcostsaved", {
        params: {
          wasteitem: currentWasteItem,
          id: id,
          day: dayvalue,
        },
      })
      .then((response) => {
        console.log(response.data);
        setPercentageChangecost(parseFloat(response.data.percentage_change).toFixed(2))
        setColorWastecost(response.data.color)
        seTotalCost(response.data.total_cost_current)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dayvalue, wasteitem, id]);

    const handleDropdownChange = (event) => {
      const newSelectedStream = event.target.value;
      setSelectedStream(newSelectedStream);
      setCurrentWasteItem(newSelectedStream);
      navigate(`/Savings/${currentbinid}/${newSelectedStream}`);
    };

    const handleBinDropDownChange = (event) =>{
      const newSelectedBin = event.target.value;
      setCurrentBinId(newSelectedBin);
      navigate(`/Savings/${newSelectedBin}/${currentWasteItem}`);
    }

    const translations = {
      day: 'dag',
      week: 'week',
      month: 'maand',
      '3months': '3 maanden',
      '6months': '6 maanden',
      year: 'jaar'
    };
    const translatedSelectedButton = language === "Dutch" ? translations[selectedButton] : selectedButton;
    const message1 = `Totaal CO2 Besparing ${translatedSelectedButton}`;
    const message2 = `Totaal Kosten Besparing ${translatedSelectedButton}`;


  return (
    <div
      style={{ minHeight: "100vh" }}
      class="bg-white dark:bg-plaex-dark dark:text-white"
      // id="wasteDashboard"
    >
      {/* Nav and Side Bar Import */}
      <Navbar />
      {/* <Sidebar onBinClick={handleClick} /> */}
      <Hamburger onBinClick={handleClick} />

      {/* Buttons for filtering waste by duration */}
      <div className="h-full ml-16 mt-24 mb-10 md:ml-64 p-4 lg:ml-64 xs:ml-0 sm:ml-0 relative">
      <div className="Waste-Filter-Container">
        <div className="Waste-Filter-Container-title sm:flex-col xs:flex-col lg:flex-row md:flex-row text-xl sm:-mt-4 xs:-mt-4 lg:mt-0 md:mt-0 w-full lg:justify-start xs:justify-center sm:justify-center md:justify-start items-center capitalize flex flex-row gap-x-2 sm:gap-y-3 xs:gap-y-3">
          <div className="flex flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col space-3">
            <span className="text-xl font-semibold xs:text-center">
            {language === "Dutch"
            ? selectedstream === "organic"
              ? "Biologisch"
              : selectedstream === "PMD"
              ? "PMD"
              : selectedstream === "paper"
              ? "Papier"
              : selectedstream === "residual"
              ? "Resterend"
              : selectedstream
            : wasteitem}

              </span>
            <div className="flex space-x-3 ml-2">
              <div
                className="capitalize bg-plaex-light-green flex flex-row p-1 text-center items-center justify-center rounded-lg"
              >
                <img src={residual} className="h-6 w-6"/>
                {/* <span className="text-[18px]">{currentbinid}</span> */}
                <select className="select bg-plaex-light-green text-sm" size="1" onChange={handleBinDropDownChange}>
                  {binIds.map((binId) => (
                    <option key={binId} value={binId}>
                      {binId}
                    </option>
                  ))}
                    {binIds.length > 0 && <option value="all">All</option>}
                </select>
              </div>
              
              <select onChange={handleDropdownChange} className="border-2 xs:w-24  xs:h-8 border-plaex-bar flex items-center justify-center rounded-lg text-sm lg:ml-auto md:ml-0 sm:ml-0 xs:ml-0">
                  <option value = "all">{language === "Dutch" ? "Alle": "All"}</option>
                  <option value="organic">{language === "Dutch" ? "Biologisch": "Organic"}</option>
                  <option value="PMD">{language === "Dutch" ? "PMD" : "PMD"}</option>
                  <option value="paper">{language === "Dutch" ? "Papier" :"Paper"}</option>
                  <option value="residual">{language === "Dutch" ? "Restafval" : "Residual"}</option>
              </select>

            </div>

          </div>

        </div>
        <div className="Filter-Buttons">
          <Button
            text={language === "Dutch" ? "1 Dag" : "1 Day"}
            onClick={(event) => handleClick("day")}
            value="day"
            className={selectedButton === 'day' ? 'selected' : ''}
          />
          <Button
            text="1 Week"
            onClick={(event) => handleClick("week")}
            value="week"
            className={selectedButton === 'week' ? 'selected' : ''}
          />
          {/* <Button text="2 Weeks" onClick={(event)=>handleClick} /> */}
          <Button
            text={language === "Dutch" ? "1 Maand" : "1 Month"}
            onClick={(event) => handleClick("month")}
            value="month"
            className={selectedButton === 'month' ? 'selected' : ''}
          />
          <Button
            text= {language === "Dutch" ? "3 Maanden" : "3 Months"}
            onClick={(event) => handleClick("3months")}
            value="3months"
            className={selectedButton === '3months' ? 'selected' : ''}
          />
          <Button
            text={language === "Dutch" ? "6 Maanden" : "6 Months"}
            onClick={(event) => handleClick("6months")}
            value="6months"
            className={selectedButton === '6months' ? 'selected' : ''}
          />
          <Button
            text={language === "Dutch" ? "1 Jaar" : "1 Year"}
            onClick={(event) => handleClick("year")}
            value="year"
            className={selectedButton === 'year' ? 'selected' : ''}
          />
        </div>
        
        <div className="flex items-center w-full justify-between mb-7">
          <div className="flex flex-row space-x-4">

          <div className="mt-4 flex flex-col items-center justify-center">
            <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message1}` : `Total CO2 Savings ${selectedButton}`}</span>
                <div className="flex items-center justify-center gap-4">
                  <span className="font-semibold flex items-center justify-center text-base">{totalwasteproduced}<span className="text-sm font-light">&nbsp;CO2e</span></span>{" "}
            <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste == "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
            </div>
        </div>

        {/* <div className="mt-4 flex flex-col items-center justify-center">
            <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message2}` : `Total Cost Savings ${selectedButton}`}</span>
                <div className="flex items-center justify-center gap-4">
                  <span className="font-semibold flex items-center justify-center text-base">{totalcost}<span className="text-sm font-light">&nbsp;CO2e</span></span>{" "}
            <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechangecost}% {colorwastecost == "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
            </div>
        </div> */}

          </div>
          <AiTwotoneCalendar className="text-2xl text-plaex_calendar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] cursor-pointer mr-10" onClick={handleCalendarClick}/>


        </div>


      </div>
          
      <div className="grid lg:grid-cols-7 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
        <div className="col-span-4">
          <CO2SavingsChart id={id} wasteitem={currentWasteItem} dayvalue={dayvalue} datevalue={datevalue} />
        </div>
        <div className="col-span-3">
          <CostSavings id={id} wasteitem={currentWasteItem} dayvalue={dayvalue} datevalue={datevalue}/>
        </div>

      </div>

      {calendarclick && (
                <div className={calendarclick? "z-50 flex flex-col rounded-lg shadow-lg absolute top-32 bg-white w-[98%]" : "z-50  bg-white flex flex-col rounded-lg shadow-lg absolute top-32 w-[98%]"}>
                  <div className="flex items-start justify-start">
                    <button onClick={handleCloseCalendar} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
                  </div>
                    <CustomCalendar datevalue={datevalue} onDateValueChange={handleDateValueChange}/>
                </div>

        )}


    
      </div>
    </div>
  );
};

export default CO2Savings;
