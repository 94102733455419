import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from 'context/LanguageContext';
import axiosInstance from 'api/axios';

const Recommendation = () => {

  const [tip, setTip] = useState(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchTip = async () => {
      try {
        const response = await axiosInstance.get('/dailytips');
        setTip(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTip();
  }, []);

  if (!tip) {
    return <div>Loading...</div>;
  }

  return (
    <div className='lg:ml-[220px] md:ml-[220px] xs:ml-0 sm:ml-0 p-10 -mt-16'>
              <section className="topSection" class="flex flex-col shadow-lg p-10 ">
                <span className='text-base font-semibold'>
                  {language === "Dutch" ? "Dagelijkse Tips" : "Daily Tips"}
                  {language === "Dutch" ? 
                    (tip.stream === "organic" ? "(Organisch)" : 
                    tip.stream === "pmd" ? "(PMD)" :
                    tip.stream === "paper" ? "(Papier)" :
                    "(Restafval)")
                  : 
                    `(${tip.stream})`}
                </span>
                <span>{language === 'Dutch' ? tip.daily_tips_dutch : tip.daily_tips_english}</span>
              </section>
    </div>
  );

};

export default Recommendation;