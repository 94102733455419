import axiosInstance from "api/axios";
import { useEffect } from "react";
const LogoutPage = () => {
  useEffect(() => {
    (async () => {
      try {
        await axiosInstance.post("/user/logout", {
          withCredentials: true,
        });
        localStorage.removeItem("loggedin");
        window.location.href = "/login";
      } catch (e) {
        console.log("logout not working", e);
      }
    })();
  }, []);
  return <div></div>;
};

export default LogoutPage;
