// import { BarElement, CategoryScale, Chart, LinearScale, Title } from "chart.js";
// import React, { useEffect, useState,useContext } from "react";
// import { Bar } from "react-chartjs-2";
// import ChartDataLabels from "chartjs-plugin-datalabels";
// import axiosInstance from "api/axios";
// import { LanguageContext } from "context/LanguageContext";

// Chart.register(BarElement, CategoryScale, LinearScale, ChartDataLabels, Title);

// const SubclassDisplayChart = (props) => {
//   const [chartData, setChartData] = useState({});
//   const {id,wasteitem, dayvalue} = props
//   const [dailyvalue, setDailyValue] = useState([])
//   const {language} = useContext(LanguageContext);

//   useEffect(() => {
//     axiosInstance
//       .get('/subclassdisplay', {
//         params: {
//           id: id,
//           day: dayvalue
//         }
//       })
//       .then(response => {
//         console.log(response.data)
//         const data = response.data;
  
//         // Extract the unique labels (categories) from the response
//         const labels = Object.keys(data);
  
//         // Extract the unique sublabels (subcategories) from the response
//         const sublabels = Array.from(
//           new Set(labels.flatMap(label => Object.keys(data[label])))
//         );

//         console.log(sublabels)
  
//         // Create an array to hold the datasets
//         const datasets = sublabels.map(sublabel => {
//           return {
//             label: sublabel,
//             data: labels.map(label => data[label][sublabel] || 0), // Handle missing data
//             backgroundColor: "#004800",
//             borderRadius: 7,
//             borderColor: "white", // Color of the border
//     borderWidth: 2, 
//             borderColor: [],
//             barThickness: 50,
//             maxBarThickness: 50,
//             minBarLength: 1,
//             datalabels: {
//               color: "white",
//             },
//           };
//         });
  
//         // Create the chart data object
//         const chartData = {
//           labels: labels,
//           datasets: datasets
//         };
  
//         setChartData(chartData);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }, [dayvalue, wasteitem, id]);
  

//   const translateToDutch = (text) => {
//   const translations = {
//     "Monday": "Maandag",
//     "Tuesday": "Dinsdag",
//     "Wednesday": "Woensdag",
//     "Thursday": "Donderdag",
//     "Friday": "Vrijdag",
//     "Saturday": "Zaterdag",
//     "Sunday": "Zondag",
//     "January": "Januari",
//     "February": "Februari",
//     "March": "Maart",
//     "April": "April",
//     "May": "Mei",
//     "June": "Juni",
//     "July": "Juli",
//     "August": "Augustus",
//     "September": "September",
//     "October": "Oktober",
//     "November": "November",
//     "December": "December"
//   };
//   return translations[text] || text;
// }


//   return (
//     <div className="Waste-Chart-Container" >
//       {chartData.labels && chartData.datasets && (
//         <Bar
//           data={chartData}
//           plugins={[ChartDataLabels]}
//           options={{
//             responsive: true,
//             maintainAspectRatio: false,
//             scales: {
//               x: {
//                 stacked: true,
//                 grid: {
//                   display: false,
//                 }
//               },
//               y: {
//                 stacked: true,
//                 type: "linear",
//                 display: false,
//                 position: "right",
//               },
              
//             },

//             plugins: {
//               legend: {
//                 display: false,
//                 padding: 10,
//               },
//               datalabels: {
//                 align: 'start',
//                 anchor: 'start',        
//                 display: true,
//                 font: {
//                   size: 12,
//                 },
//                 formatter: (value) => {
//                   if (value === 0) {
//                     return null;
//                   }
//                   return value + "%";
//                 },
//                 align: "start",
//                 anchor: "end",
//               },
//             },
//           }}
//         />
//       )}
//     </div>
//   );
// };

// export default SubclassDisplayChart;

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axiosInstance from 'api/axios';
import CustomCalendar from "common/Components/Calendar/Calendar";
import {AiTwotoneCalendar, AiOutlineClose} from "react-icons/ai"
import {AiFillInfoCircle} from 'react-icons/ai'

const SubclassDisplayChart = (props) => {
  const {id, wasteitem, dayvalue} = props;
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [calendarclick, setCalendarClick] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [iscustomDate, setIsCutomDate] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [datevalue, setDateValue] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  useEffect(() => {
  resetDates();
}, [dayvalue]);

  const resetDates = () => {
    setDateValue([
      {
        startDate: null,
        endDate: null,
        key: 'selection'
      }
    ]);
  };


  const handleCloseCalendar = () => {
    setCalendarClick(false);
  }
  const handleDateValueChange = (newDateValue) => {
    setDateValue(newDateValue);
    setCalendarClick(false)
  }

  const handleCalendarClick = () =>{
    setCalendarClick(!calendarclick);
  }

  const handleModalClick = () =>{
    setModalIsOpen(false)
  }


useEffect(() => {
  let startDate = new Date(datevalue[0].startDate);
  let endDate = new Date(datevalue[0].endDate);

  const defaultDateString = 'Thu Jan 01 1970 03:00:00 GMT+0300 (East Africa Time)';

  if (startDate.toString() === defaultDateString) {
    startDate = null;
  }

  if (endDate.toString() === defaultDateString) {
    endDate = null;
  }
  axiosInstance
    .get('/subclassdisplay', {
      params: {
        id: id,
        day: dayvalue,
        startDate: startDate,
        endDate: endDate,
      }
    })
    .then(response => {
      const data = response.data;
      const labels = Object.keys(data);
      console.log(data)
      const sublabels = Array.from(new Set(labels.flatMap(label => Object.keys(data[label]))));

      const series = sublabels.map(sublabel => {

        let color;
        // sublabel = sublabel || 'unidentified'; 
        switch(sublabel) {
          case 'organic':
            color = ['#004800', '#006400', '#008000', '#32CD32', '#90EE90']; // shades of green
            break;
          case 'pmd':
            color = ['#FFFF00', '#FFFF33', '#FFFF66', '#FFFF99', '#FFFFCC']; // shades of yellow
            break;
          case 'paper':
            color = ['#0000FF', '#3333FF', '#6666FF', '#9999FF', '#CCCCFF']; // shades of blue
            break;
          case 'rest':
            color = ['#8B4513', '#A0522D', '#D2691E', '#CD853F', '#F4A460']; // shades of brown
            break;
          default:
            color = ['#000000']; // default color if none of the conditions match
        }
        return {
          name: sublabel,
          data: labels.map(label => data[label][sublabel] || 0), // Handle missing data
          fill: {
            colors: color,
          },
          borderRadius: 10,
        };
      });

      setSeries(series);
      setCategories(labels);
    })
    .catch(error => {
      console.error(error);
    });
}, [dayvalue, wasteitem, id, datevalue]);


  const options = {
    chart: {
      type: 'bar',
      height: 400,
      stacked: true,
      borderRadius: 10,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '40%',
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: categories,
      max: 100,
      title: {
        text: '% Contributions', // Set x-axis label
      },
    },
    
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    },
   dataLabels: {
    enabled: true,
    formatter: function (val) {
      // Check if the value is numeric
      if (!isNaN(val)) {
        // If the value is numeric, append a percentage sign to it
        return val.toFixed(1) + "%";
      } else {
        // If the value is not numeric, return it as it is
        return val;
      }
    },
    style: {
      colors: ['#fff']
    }
  },
}

  return (
    <div className="Waste-Chart-Container relative">
      <div className='flex items-center'>
      <AiFillInfoCircle
          className="h-5 w-5 text-plaex-bar hover:text-green-500 cursor-pointer" 
          onClick={() => setModalIsOpen(!modalIsOpen)}
        />
      <div className='flex justify-start'>
        <AiTwotoneCalendar className="text-2xl text-plaex_calendar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] cursor-pointer" onClick={handleCalendarClick}/>
      </div>
      </div>
   

      <Chart options={options} series={series} type="bar" height={450} />
      {calendarclick && (
                <div className={calendarclick? "z-50 flex flex-col bg-white rounded-lg shadow-lg absolute top-0 w-full" : "z-50 flex flex-col rounded-lg shadow-lg absolute w-full bg-white"}>
                  <div className="flex items-start justify-start">
                    <button onClick={handleCloseCalendar} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
                  </div>
                    <CustomCalendar datevalue={datevalue} onDateValueChange={handleDateValueChange}/>
                </div>

        )}

        {modalIsOpen && (
          <>
            <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg p-6 pb-10 z-50 w-full sm:max-w-md">
                <button onClick={handleModalClick} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
                <div>
                  This interactive chart provides a visual representation of the subclasses each waste is associated with. Each data point on the chart corresponds to a specific subclass of waste for a specific stream, allowing you to visualize the different waste classes included inside of a waste steam. Toggle between the date filters to see the different subclasses recorded for different time periods. 
                </div>

              </div>
            </div>
          </>
        )}
    </div>

    
  );
};

export default SubclassDisplayChart;

