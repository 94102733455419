import logo from "Assets/svg/plaexLogo.svg";
import axiosInstance from "api/axios";
import { useEffect, useRef, useState, useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import "./Login.css";
import { Language } from "@mui/icons-material";
const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [succesMsg, setSucessMsg] = useState("");
  const errRef = useRef();
  const userRef = useRef();
  const {language} = useContext(LanguageContext)

  const successWait = async () => {
    await setTimeout(3000);
    setSucessMsg("");
  };
  //focus every time component load
  useEffect(() => {
    userRef.current.focus();
  }, []);

  //remove Err if user change values
  useEffect(() => {
    setErrMsg("");
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = {
      email: email,
    };
    try {
      await axiosInstance.post("/user/password_reset/", user, {
        withCredentials: true,
      });
      setEmail("");
      setSucessMsg("Email Sent");

      errRef.current.focus();
      //add notification that email is sent
    } catch (err) {
      setErrMsg("Email not valid");
      console.log("User not found", err);
      errRef.current.focus();
    }
  };
  return (
    <div className="bg-white">
      <div className="fixed top-0 z-50 w-full px-4 py-4 bg-white">
        <nav className="px-3 py-2 lg:px-24 lg:pl-6">
          <div className="flex justify-between">
            <div className="flex items-center justify-start">
              <a href="/" className="flex w-16 ml-2 md:mr-24">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="flex items-center gap-x-4">
              <a href="/login">
                <button className="text-md font-bold hover:underline decoration-green-600 underline-offset-8 decoration-4 ">
                  {language === "Dutch" ? "Aanmelden" : "Login"}
                </button>
              </a>
            </div>
          </div>
        </nav>
      </div>
      <div className="relative z-10 flex items-center justify-center ">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 flex items-center justify-center h-screen ">
          <div className="mx-auto max-w-lg rounded-lg shadow-lg p-4 bg-slate-100 sm:p-6 lg:p-8">
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscrean"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <p
              ref={errRef}
              className={succesMsg ? "successmsg" : "offscrean"}
              aria-live="assertive"
            >
              {succesMsg}
            </p>
            <h1 className="text-center text-2xl font-bold  sm:text-3xl">
              {language === "Dutch" ? "Wachtwoord Opnieuw Instellen" : "Reset Password"}
            </h1>

            <form
              className="mt-6 mb-0 space-y-4  p-4  sm:p-6 lg:p-8"
              onSubmit={handleSubmit}
            >
              <p className="text-xs pb-3">
                {language === "Dutch" ? "Voer hieronder uw e-mailadres in en u ontvangt een e-mail met een link voor het opnieuw instellen van uw wachtwoord." :"Enter your email below and you will be sent an email with a password reset link."}
              </p>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="w-full rounded-lg border-2 p-3 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                placeholder={language === "Dutch" ? "Voer email": "Enter email"}
                value={email}
                ref={userRef}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                type="submit"
                className="block w-full rounded-lg bg-green-600 px-5 py-3 text-sm text-white font-medium"
              >
                {language === "Dutch" ? "Koppeling opnieuw instellen verzenden":"SEND RESET LINK"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
