import PMD from "Assets/svg/PMD.svg";
import paper from "Assets/svg/Paper.svg";
import organic from "Assets/svg/organic.svg";
import residual from "Assets/svg/residual.svg";
import axiosInstance from "api/axios";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { truncate } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {MdError } from 'react-icons/md'
import { useNavigate } from "react-router-dom";
import "../Components/WasteChart.css";
import { LanguageContext } from "context/LanguageContext";

const WasteChart = (props) => {
  const { selectedBin } = props;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [defaultbin, setDefaultBin] = useState('')
  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  const { language } = useContext(LanguageContext);

  const navigate = useNavigate();

  const incrementDate = () => {
    const newDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    const currDate = new Date();

    if (newDate > currDate) {
    } else {
      const formattedDate = newDate.toISOString().split("T")[0];
      setSelectedDate(formattedDate);
      console.log(selectedDate)
      setCurrentDate(newDate);
    }
  };
  const decrementDate = () => {
    const newDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
    const formattedDate = newDate.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
    setCurrentDate(newDate);
  };

  useEffect(() => {
    fetchWasteLevelByDate();
  }, [selectedDate]);

  useEffect(() => {
    ChartJS.register(
      BarElement,
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,
      ChartDataLabels
    );
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/get_bins")
      .then((response) => {
        console.log('bin data is', response.data)
        for (let i = 0; i < response.data.length; i++) {
          setDefaultBin(response.data[0].unique_identifier)
          break
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(()=>{
    fetchWasteLevel()
  },[])

  const [wasteChart, setWasteChart] = useState(0);
  const [bin, setBin] = useState([]);

  async function fetchWasteLevel() {
    let data = null;
    if (selectedBin === undefined) {
      if (selectedDate === "") {
        const response = await axiosInstance.get(`/getlatestfilllevel`);
        data = response.data;
      } else if (selectedDate !== "") {
        const response = await axiosInstance.get(
          `/getfilllevelbydate/${selectedDate}`
        );
        data = response.data;
      }
      console.log(data);
    } else {
      if (selectedDate != "") {
        const response = await axiosInstance.get(
          `/getlatestfilllevelbybinanddate/${selectedBin.id}/${selectedDate}`
        );
        data = response.data;
      } else {
        const response = await axiosInstance.get(
          `/getlatestfilllevelbybin/${selectedBin.id}/`
        );
        data = response.data;
      }

      console.log(data);
    }
    setWasteChart(data);
  }

  const labels = ["pseudo-label"];
  const organicWasteChartData = {
    labels: labels,
    datasets: [
      {
        axis: "y",
        data: [wasteChart.organic_waste_percentage, 100],
        fill: false,
        backgroundColor: ["#004800"],

        borderRadius: 10,
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 1,
      },
    ],
  };
  const PMDWasteChartData = {
    labels: labels,
    datasets: [
      {
        axis: "y",
        data: [wasteChart.pmd_waste_percentage, 100],
        fill: false,
        backgroundColor: ["#004800"],

        borderRadius: 10,
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 1,
      },
    ],
  };
  const paperWasteChartData = {
    labels: labels,
    datasets: [
      {
        axis: "y",
        data: [wasteChart.paper_waste_percentage, 100],
        fill: false,
        backgroundColor: ["#004800"],

        borderRadius: 10,
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 1,
      },
    ],
  };
  const residualWasteChartData = {
    labels: labels,
    datasets: [
      {
        axis: "y",
        data: [wasteChart.residual_waste_percentage, 100],
        fill: false,
        backgroundColor: ["#004800"],

        borderRadius: 10,
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 20,
        minBarLength: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          // drawOnChartArea: false,
        },

        // to hide yaxis
        ticks: {
          font: {
            size: 0,
          },

          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },

        border: {
          display: false,
        },
      },

      x: {
        grid: {
          display: false,
        },
        // to hide xaxis
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          font: {
            size: 0,
            weight: 600,
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },

        border: {
          display: false,
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        font: {
          size: 10.5,
        },
        // formatter: (value) => {
        //   let percentage = value + "%";
        //   return percentage;
        // },
        // align: "start",
        // anchor: "end",
      },
    },
  };

  const barPattern = {
    id: "barPattern",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { top, bottom, height },
        scales: { x, y },
      } = chart;

      ctx.save();
      chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
        ctx.fillRect(x.getPixelForValue(index), top, 10000, height - 0.5);
        ctx.fillStyle = "#E1E1E1";
      });
    },
  };

  async function fetchWasteLevelByDate() {
    let data = null;
    console.log("called");
    if (selectedBin === undefined) {
      if (selectedDate === "") {
        const response = await axiosInstance.get(
          `/getfilllevelbydate/${selectedDate}`
        );
        data = response.data;
      } else if (selectedDate !== "") {
        const response = await axiosInstance.get(
          `/getfilllevelbydate/${selectedDate}`
        );
        data = response.data;
      }
      console.log(data);
    } else {
      const response = await axiosInstance.get(
        `/getlatestfilllevelbybinanddate/${selectedBin.id}/${selectedDate}`
      );
      data = response.data;
      console.log(data);
    }
    setWasteChart(data);
  }

  function fetchBins() {
    axiosInstance
      .get("/get_bins")
      .then((response) => {
        setBin(response.data);

        for (let i = 0; i < response.data.length; i++) {
          const binObj = response.data[i];
          if (binObj.is_garfill === true) {
            let element = document.querySelector(".WasteChartContainer");
            element.style.display = "none";
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchWasteLevel();
    fetchBins();
  }, [selectedBin]);

  if (!wasteChart) {
    return <div>Loading API</div>;
  }

  function handleWasteChart(value) {
    if (selectedBin == undefined) {
      const wasteitem = value;
      navigate(`/waste-dashboard/${defaultbin}/${wasteitem}`)
    } else {
      const id = selectedBin.unique_identifier;
      const wasteitem = value;
      console.log(wasteitem);
      navigate(`/waste-dashboard/${id}/${wasteitem}`);
    }
  }

  return (
    <div className="WasteChartContainer ">
      <section id="date" class="flex xs:gap-x-4 gap-x-8 lg:justify-start md:justify-start sm:justify-center xs:justify-center sm:text-[14px] xs:text-[14px] sm:mt-6 xs:mt-6 lg:mt-0 md:mt-0 lg:text-sm sm:-ml-[60px] xs:-ml-[60px] lg:ml-0 md:ml-0 md:text-sm mr-0 items-center">
        <button
          onClick={decrementDate}
          class="backward bg-gray-100 p-3 rounded-full "
        >
          <IoIosArrowBack />
        </button>
        <div className="dateOutput">
          <h4 class="font-semibold ">
            {currentDate.toLocaleDateString(language === 'Dutch' ? 'nl-NL' : undefined, dateOptions)}
          </h4>
        </div>
        <button
          onClick={incrementDate}
          class="forward bg-gray-100 p-3 rounded-full"
          disabled={incrementDate > new Date()}
        >
          <IoIosArrowForward />
        </button>
      </section>

      <section id="wasteChart" class="flex gap-4 mt-10 mb-10 lg:ml-0 md:ml-0 sm:-ml-[40px] xs:-ml-[40px]">
        <div className="top">
          <div
            class="waste-item grow shadow-md h-24 rounded-lg p-4 hover:bg-plaex-light-green"
            onClick={() => handleWasteChart("organic")}
          >
          <div className="flex flex-row justify-between">
              <h4 class="font-bold text-xl pb-1">
                {wasteChart.latest_organic_waste}%
              </h4>
              {selectedDate === "" || selectedDate === new Date().toISOString().slice(0, 10) ? (
                  wasteChart.latest_organic_waste >= 80 && wasteChart.latest_organic_waste <= 100 ? (
                    <MdError className="text-red-500 animate-pingnonstop text-3xl mr-3 object-contain cursor-pointer"  data-tooltip-id="organic" data-tooltip-content="Empty the Organic Stream!"/>
                  ) : null
                ) : null}
              <ReactTooltip id="organic"/>
            </div>
            <div className="waste-item-chart">
              <div class="waste-item-chart-name flex gap-2 text-sm font-medium	">
                <img src={organic} alt="" />
                <p>{language === "Dutch" ? "Biologisch" : "Organic"}</p>
              </div>

              <div className="h-[5px] mt-2 rounded-full w-[100%] bg-gray-200 ml-[10px]">
                <div
                  className="h-full bg-green-700 rounded-l-lg"
                  style={{ width: `${wasteChart.latest_organic_waste}%` }}
                ></div>
              </div>

              <div className="waste-item-chart-content"></div>
            </div>
          </div>

          <div
            class="waste-item grow shadow-md h-24 rounded-lg p-4 hover:bg-plaex-light-green"
            onClick={() => handleWasteChart("PMD")}
          >
          <div className="flex flex-row justify-between">
              <h4 class="font-bold text-xl pb-1">
                {wasteChart.latest_pmd_waste}%
              </h4>
              {selectedDate === "" || selectedDate === new Date().toISOString().slice(0, 10) ? (
                wasteChart.latest_pmd_waste >= 80 && wasteChart.latest_pmd_waste<=100 ? (
                  <MdError className="text-red-500 text-3xl mr-3 object-contain cursor-pointer"  data-tooltip-id="pmd" data-tooltip-content="Empty the PMD Stream!"/>
                ) : null
              ) : null}
              <ReactTooltip id="pmd"/>
            </div>
            <div className="waste-item-chart">
              <div class="waste-item-chart-name flex gap-2 text-sm font-medium	">
                <img src={PMD} alt="" />
                <p>{truncate("PMD", { length: 20 })}</p>
              </div>
              <div className="h-[5px] mt-2 rounded-full w-[100%] bg-gray-200 ml-[10px]">
                <div
                  className="h-full bg-green-700 rounded-l-lg"
                  style={{ width: `${wasteChart.latest_pmd_waste}%` }}
                ></div>
              </div>
              <div className="waste-item-chart-content"></div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div
            class="waste-item grow shadow-md h-24 rounded-lg p-4 hover:bg-plaex-light-green "
            onClick={() => handleWasteChart("paper")}
          >
        <div className="flex flex-row justify-between">
              <h4 class="font-bold text-xl pb-1">
                {wasteChart.latest_paper_waste}%
              </h4>
              {selectedDate === "" || selectedDate === new Date().toISOString().slice(0, 10) ? (
                wasteChart.latest_paper_waste >= 80 && wasteChart.latest_paper_waste<=100 ? (
                  <MdError className="text-red-500 text-3xl mr-3 object-contain cursor-pointer"   data-tooltip-id="paper" data-tooltip-content="Empty the Paper Stream!"/>
                ) : null
              ) : null}
              <ReactTooltip id="paper"/>
            </div>
            <div className="waste-item-chart">
              <div class="waste-item-chart-name flex gap-2 text-sm font-medium	">
                <img src={paper} alt="" />
                <p>{language === "Dutch" ? "Papier" : "Paper"}</p>
              </div>
              <div className="h-[5px] mt-2 rounded-full w-[100%] bg-gray-200 ml-[10px]">
                <div
                  className="h-full bg-green-700 rounded-l-lg"
                  style={{ width: `${wasteChart.latest_paper_waste}%` }}
                ></div>
              </div>
              <div className="waste-item-chart-content"></div>
            </div>
          </div>

          <div
            class="waste-item grow shadow-md h-24 rounded-lg p-4 hover:bg-plaex-light-green"
            onClick={() => handleWasteChart("residual")}
          >
            <div className="flex flex-row justify-between">
              <h4 class="font-bold text-xl pb-1">
                {wasteChart.latest_residual_waste}%
              </h4>
              {selectedDate === "" || selectedDate === new Date().toISOString().slice(0, 10) ? (
                wasteChart.latest_residual_waste >=80 && wasteChart.latest_residual_waste <= 100 ? (
                  <MdError className="text-red-500 text-3xl mr-3 object-contain cursor-pointer"   data-tooltip-id="residual" data-tooltip-content="Empty the Residual Stream!"/>
                ) : null
              ) : null}
              <ReactTooltip id="residual"/>
            </div>

            <div className="waste-item-chart">
              <div class="waste-item-chart-name flex gap-2 text-sm font-medium	">
                <img src={residual} alt="" />
                <p>{language === "Dutch" ? "Resterend" :"Residual"}</p>
              </div>
              <div className="h-[5px] mt-2 rounded-full w-[100%] bg-gray-200 ml-[10px]">
                <div
                  className="h-full bg-green-700 rounded-l-lg"
                  style={{ width: `${wasteChart.latest_residual_waste}%` }}
                ></div>
              </div>
              <div className="waste-item-chart-content"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WasteChart;
