import axios from "axios";

const baseURL = "https://dashboard.plaex.net/api/";
// const baseURL = "http://127.0.0.1:8000/api/";

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosInstance.defaults.xsrfCookieName = "csrftoken";
axiosInstance.defaults.xsrfHeaderName = "X-CSRFToken";
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 403) {
      window.location.href = "/";
      localStorage.removeItem("loggedin");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
