import React from 'react';
import ReactApexChart from 'react-apexcharts';

const GHGChart = ({ data }) => {
  const options = {
    chart: {
      id: 'ghg-chart',
      type: 'bar',
    },
    xaxis: {
      categories: data.map((item) => item.label),
    },
  };

  const series = [
    {
      name: 'GHG Emissions',
      data: data.map((item) => item.value),
    },
  ];

  return (
    <div className="ghg-chart">
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default GHGChart;
