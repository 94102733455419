import React, { useState, useEffect, useContext, useRef } from 'react';
import Chart from 'react-apexcharts';
import axiosInstance from "api/axios";
import Lottie from 'lottie-react';
import CO2Lottie from "../../../Assets/Lottie/CO2.json"
import {FaFileExport} from 'react-icons/fa'
import * as XLSX from 'xlsx';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { LanguageContext } from 'context/LanguageContext';
import { BiMenu } from "react-icons/bi";
import { CSSTransition } from 'react-transition-group';
import {AiFillInfoCircle,AiOutlineClose} from 'react-icons/ai'
import html2canvas from 'html2canvas';


const RadialHistogram = (props) => {

  const [selectedFormat, setSelectedFormat] = useState('xlsx');
  const chartRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const { language } = useContext(LanguageContext);

  const [chartData, setChartData] = useState({});
  const {id,wasteitem, dayvalue, datevalue} = props
  const [dailyvalue, setDailyValue] = useState([])
  const [valuewithout, setValueWithout] = useState([])
  const [height, setHeight] = useState(300);
  const [tier, setTier] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModalClick = () =>{
    setModalIsOpen(false)
  }

  useEffect(()=>{

    let startDate = new Date(datevalue[0].startDate);
    let endDate = new Date(datevalue[0].endDate);
  
    const defaultDateString = 'Thu Jan 01 1970 03:00:00 GMT+0300 (East Africa Time)';
  
    if (startDate.toString().includes('Jan 01 1970')) {
      startDate = null;
    }
    
    if (endDate.toString().includes('Jan 01 1970')) {
      endDate = null;
    }
    axiosInstance.get('/costsavings', {params: {
      wasteitem: wasteitem,
      id: id,
      day: dayvalue,
      startDate: startDate,
      endDate: endDate,
  }})
    .then(response => {
      console.log(response.data)
      setDailyValue(response.data.total_savings)
      setValueWithout(response.data.produced)
      // if(dayvalue === "year"){
      //   setHeight(400)
      // }
      // else{
      // setHeight(400)
      // }
    })
    .catch(error => {
      console.error(error);
    });
  },[dayvalue, wasteitem, id, datevalue])


  useEffect(() => {
    axiosInstance
        .get("/usertier")
        .then((response) => {
            console.log(response.data.tier)
            setTier(response.data.tier)
        })
        .catch((error) => {
        console.error(error);
        });
    }, []);

    const translateToDutch = (text) => {
  const translations = {
    "Monday": "Maandag",
    "Tuesday": "Dinsdag",
    "Wednesday": "Woensdag",
    "Thursday": "Donderdag",
    "Friday": "Vrijdag",
    "Saturday": "Zaterdag",
    "Sunday": "Zondag",
    "January": "Januari",
    "February": "Februari",
    "March": "Maart",
    "April": "April",
    "May": "Mei",
    "June": "Juni",
    "July": "Juli",
    "August": "Augustus",
    "September": "September",
    "October": "Oktober",
    "November": "November",
    "December": "December",
    "organic": "Biologisch",
    "PMD" : "PMD",
    "paper" :"Papier",
    "residual" : "Restafval",
    "rest" : "Restafval",

  };
  return translations[text] || text;
}

  const exportToExcel = (dailyValue, dayValue) => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    let savingsLabel = language === "Dutch" ? "KostenBesparingen" : "Cost Savings";
  
    // Prepare the data
    const data = Object.keys(dailyValue).map(key => {
      let translatedKey = language === "Dutch" ? translateToDutch(key) : key;
      return {
        'Type': translatedKey,
        [dayValue]: dailyValue[key]
      };
    });
  
    // Convert the data to worksheet
    const ws = XLSX.utils.json_to_sheet(data, {header: ["Type", dayValue], skipHeader: true});
  
    // Make the header bold
    const range = XLSX.utils.decode_range(ws['!ref']);
    for(let R = range.s.r; R <= range.e.r; ++R) {
      for(let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = {c: C, r: R};
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if(ws[cell_ref]) {
          ws[cell_ref].s = {font: {bold: true}};
        }
      }
    }
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Get today's date and format it as dd-mm-yyyy
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
  
    // Write the workbook to a file with the current date in the filename
    XLSX.writeFile(wb, `${savingsLabel}_${formattedDate}.xlsx`);
  };
  
  const exportToCSV = (dailyValue, dayValue) => {
  let savingsLabel = language === "Dutch" ? "KostenBesparingen" : "Cost Savings";

  // Prepare CSV data
  const csvData = [];
  
  // Create the header row
  const headerRow = ["Type", dayValue]; // Modify headers as needed
  csvData.push(headerRow);

  // Create rows with data
  Object.keys(dailyValue).forEach((key) => {
    let translatedKey = language === "Dutch" ? translateToDutch(key) : key;
    
    const rowData = [
      translatedKey,
      dailyValue[key]
    ]; // Modify data fields as needed
    csvData.push(rowData);
  });

  // Convert data to CSV string
  const csvContent = csvData.map((row) => row.join(",")).join("\n");

  // Create a Blob and trigger a download
  const blob = new Blob([csvContent], { type: "text/csv" });
  if (window.navigator.msSaveBlob) {
    // For IE
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
    window.navigator.msSaveBlob(blob, `${savingsLabel}_${formattedDate}.csv`);
  } else {
    // For modern browsers
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${savingsLabel}.csv`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const exportChartAsPNG = () => {

  const chartSection = document.getElementById('CostSavingsChart');

  html2canvas(chartSection).then(canvas => {

    const image = canvas.toDataURL('image/png');

    // Generate filename
    let savingsLabel = language === "Dutch" ? "KostenBesparingen" : "Cost Savings";
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
    const filename = `${savingsLabel}_${date}.png`;

    // Pass filename to save function
    saveDataURLToFile(image, filename);

  });

};
const saveDataURLToFile = (dataURL, filename) => {

  // convert data URL to blob
  const blob = dataURLToBlob(dataURL);  

  // create object URL from blob 
  const url = URL.createObjectURL(blob);

  // create anchor tag
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  // click to download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

};

const dataURLToBlob = (dataURL) => {
  // Convert base64 to raw binary data held in a string
  const byteString = atob(dataURL.split(',')[1]);

  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

  const arrayBuffer = new ArrayBuffer(byteString.length);
  
  const ia = new Uint8Array(arrayBuffer);
  
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], {type: mimeString});
  return blob;

};



  

  const handleExporttoExcel = (option) => {
    if (option === 'xlsx') {
      exportToExcel(dailyvalue, valuewithout);
    } else if (option === 'csv') {
      exportToCSV(dailyvalue,valuewithout);
    } else if (option === 'png') {
      exportChartAsPNG();
    }
  }
  
  

  useEffect(() => {
    const labels = language === 'Dutch' ?  Object.keys(dailyvalue).map(translateToDutch) : Object.keys(dailyvalue);
    const data = Object.values(dailyvalue)


    setChartData({
      series: data,
      
      options: {
        
        chart: {
          type: 'radialBar',
        },
        title: {
          text: language === "Dutch" ? "Kosten Besparings Grafiek" : "Cost Savings Chart",
          align: 'center'
        },
        
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }},
        plotOptions: {
          radialBar: {
            // hollow: {
            //   margin: 15,
            //   size: "100%"
            // },
            dataLabels: {
              showOn: "always",
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
                formatter: function (val) {
                  return "€" + val;
                }
              },
              total: {
                show: true,
                label: language === "Dutch" ? "Totaal" :"Total",
                formatter: function (w) {
                  return "€" + w.globals.seriesTotals.reduce((a, b) => a + b, 0).toFixed(2)
                  
                }
              }
            }
          }
        },
        stroke: {
          lineCap: "round",
        },
        labels: labels,
        legend:{
          show:true,
          position:"bottom",
          labels: {
            formatter: (label) => {
              if(label === "rest") {
                return "Residual"
              }
              return label;
            }
          },
        },
      },
    });
  }, [dailyvalue, language]);

  return (
    <div>
        {tier === 'Business' && ( <div className='flex items-center text-center'>
          <AiFillInfoCircle
                        className="h-5 w-5 text-plaex-bar hover:text-green-500 cursor-pointer mr-2" 
                        onClick={() => setModalIsOpen(!modalIsOpen)}
                      />
          <div className="relative">
                  <div>
                    <button className="w-6 h-6 text-black underline hover:text-green-500 cursor-pointer" onClick={toggleDropdown}>
                      {language === "Dutch" ? "Exporteren" : "Export"}
                    </button>
                    {isDropdownOpen && (
                      <CSSTransition
                        in={isDropdownOpen}
                        timeout={200}
                        classNames="slide"
                        unmountOnExit
                      >
                        <div className="absolute flex flex-row left-16 -top-2 bg-white border border-gray-300 p-1 rounded shadow">
                          <button onClick={() => handleExporttoExcel('xlsx')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            XLSX
                          </button>
                          <button onClick={() => handleExporttoExcel('csv')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            CSV
                          </button>
                          <button onClick={() => handleExporttoExcel('png')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            PNG
                          </button>
                        </div>

                      </CSSTransition>
                    )}
                  </div>
                </div>
                  </div>
          )}

      <ReactTooltip id="co2savings"/>
    <div className={`p-20 ${height===400 ? "-mt-12" : " m-0" }`}>
      {chartData.series && chartData.series.reduce((a, b) => a + b, 0) > 0 ? (
        <Chart options={chartData.options} series={chartData.series} type="radialBar"  height={350}  id="CostSavingsChart"/>
      ) : (
        <div className="flex flex-col justify-center items-center rounded-lg -mt-2">
          <div className="w-84 flex flex-col items-center justify-center shadow-sm rounded-xl p-5">
            <Lottie animationData={CO2Lottie} className="h-52 w-52" />
            <h3 className="text-base font-semibold text-gray-400">
              {language === "Dutch"? "Geen Kosten Besparings Gegevens" : "No Cost Savings Data"}
            </h3>
          </div>
        </div>
      )}
    </div>

    {modalIsOpen && (
  <>
    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 pb-10 z-50 w-full sm:max-w-md">
        <button onClick={handleModalClick} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
        <div>
        This interactive chart provides a comprehensive view of the cost you saved over time by using our bins. You can filter the chart by  date range to see how much was saved for particular time period. Hover over any data point to see the exact figures of a particular stream for a particular time.
        You can export the filtered chart data as a CSV or XSLX file for further analysis and use in other tools.
        </div>

      </div>
    </div>
  </>
)}

    </div>
  );
  
};

export default RadialHistogram;
