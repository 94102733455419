import axiosInstance from "api/axios";
import Hamburger from "common/Components/Hamburger/Hamburger";
import Navbar from "common/Components/NavBar/NavBar";
import Sidebar from "common/Components/SideBar/SideBar";
import { useEffect, useState, useContext } from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import { LanguageContext } from "context/LanguageContext";

const Profile = () => {
  const [profiledata, setProfileData] = useState({});
  const [image, setImage] = useState();
  const [imageName, setImageName] = useState();
  const { language } = useContext(LanguageContext);

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);

    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
    console.log(image);
  };
  const handleClick = () => {};
  const handlePost = (e) => {
    console.log(image);
    const formData = new FormData();
    formData.append("user_profile", image);
    try {
      axiosInstance
        .put("/update", formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((res) => {
          console.log(res);
          console.log(formData);
        });
    } catch (e) {
      console.log("Error", e);
    }
  };
  useEffect(() => {
    axiosInstance
      .get("")
      .then((response) => {
        response.data.user_profile = response.data.user_profile.replace(
          /^http:\/\//i,
          "https://"
        );
        setProfileData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div style={{ minHeight: "100vh" }} class=" dark:bg-plaex-dark ">
      <Navbar />
      {/* <Sidebar onBinClick={handleClick} /> */}
      <Hamburger onBinClick={handleClick}/>

      <section class=" pt-16 bg-blueGray-50">
        <div class="w-full lg:w-4/12 px-4 mx-auto">
          <div class=" bg-white  shadow-lg relative flex flex-col min-w-0 break-words  w-full mb-6 pb-10 rounded-lg mt-20">
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div class="w-full px-4 flex justify-center">
                  <div class="relative">
                    <img
                      alt="Profile Picture"
                      src={profiledata.user_profile}
                      class=" mx-auto rounded-full  border-8 border-white align-middle -mt-5 w-40 h-40"
                    />
                  </div>
                </div>
                <form onSubmit={handlePost}>
                  <input
                    type="file"
                    accept="image/*"
                    enctype="multipart/form-data"
                    onChange={(e) => {
                      handleImageChange(e);
                    }}
                    id="files"
                    className="hidden"
                  />
                  <label
                    htmlFor="files"
                    className=" float-left cursor-pointer mr-4"
                  >
                    <GrDocumentUpdate size={30} />
                  </label>

                  <div className="float-left mr-2">{imageName}</div>

                  <button
                    className="rounded-full outline-none object-contain px-5 py-2 shadow-sm cursor-pointer hover:bg-plaex-light-green text-sm mr-2 focus:outline-none text-gray-600 border-[1px] border-gray-400 "
                    type="submit"
                  >
                    update
                  </button>
                </form>
                <div class="w-full text-center mt-20">
                  <div class="flex justify-center lg:pt-4 pt-8 pb-0">
                    <div class="p-3 text-center">
                      <span class="text-xl font-bold block  tracking-wide text-slate-700">
                        {profiledata.email}
                      </span>
                      <span class="text-sm text-slate-400">Email</span>
                    </div>
                    <div class="p-3 text-center">
                      <span class="text-xl font-bold block  tracking-wide text-slate-700">
                        {profiledata.company}
                      </span>
                      <span class="text-sm text-slate-400">{language === "Dutch" ? "Bedrijf" :"Company"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
