import axiosInstance from "api/axios";
import Navbar from "common/Components/NavBar/NavBar";
import Sidebar from "common/Components/SideBar/SideBar";
import React, { useEffect, useState } from "react";
import BinTable from "./Components/BinTable";
import FillLevelChart from "./Components/FillLevelChart";
import WasteChart from "./Components/WasteChart";
import "./MainDashboard.css";
import TotalWasteCount from "./Components/TotalWasteCount";
import Hamburger from "common/Components/Hamburger/Hamburger";
import Feedback from "common/Components/Feedback/Feedback";
import Contact from "common/Components/Contact/Contact";
import CustomModal from "pages/Recommendation/Components/CustomModal";
import {AiTwotoneCalendar} from "react-icons/ai"
import Recommendation from "pages/Recommendation/Recommendation";
import TotalWeightCount from "pages/WeightScale/Components/TotalWeightCount"

const MainDashboard = () => {
  const [selectedBin, setSelectedBin] = useState();
  const [counter, setCounter] = useState(0)
  const handleClick = (bin) => {
    console.log(bin)
    setSelectedBin(bin);
  };
  const [bintype, setBin] = useState(true);
  const [onoff, setOnOff] = useState({});
  const [tier, setTier] = useState('')


  useEffect(() => {
    axiosInstance
        .get("/usertier")
        .then((response) => {
            console.log(response.data.tier)
            setTier(response.data.tier)
        })
        .catch((error) => {
        console.error(error);
        });
    }, []);

  useEffect(() => {
    axiosInstance
      .get("/get_bins")
      .then((response) => {
        console.log(response.data);
        setBin(response.data);

        console.log(bintype);
        for (let i = 0; i < response.data.length; i++) {
          const binObj = response.data[i];
          if (binObj.is_garfill === true) {
            setBin(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  
  const checkBinStatus = () => {
    axiosInstance
    .get(`/isOnline`)
    .then((response) => {
      console.log(response.data)
      setOnOff(response.data)
    })
    .catch((error) => {
      console.error(error);
    });
};

  useEffect(() => {
    if(counter === 0 ){
      checkBinStatus()
      setCounter(1)
    }
    const intervalCall = setInterval(() => {
      checkBinStatus()
    }, 120000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsOpen(true);
    }, 5000); // Delay in milliseconds

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };


  return (
    <div
      // style={{ minHeight: "100vh" }}

      class="bg-white dark:bg-plaex-dark dark:text-white h-full"
    >
      {/* Nav and Side Bar Import  */}

      <Navbar />
      {/* {bintype && <Sidebar onBinClick={handleClick} />} */}
      { bintype && <Hamburger onBinClick={handleClick}/>}

      {/* <CustomModal isOpen={modalIsOpen} onRequestClose={handleCloseModal} className="p-20"/> */}

      <div
        className={`ml-16 mt-24 px-4 ${bintype ? "md:ml-64" : ""}`}
      >
        <div className="maindashboard-content flex flex-col">
          <WasteChart selectedBin={selectedBin} />
          <BinTable onBinClick={handleClick} onoff={onoff}/>
          <div className="Waste-Level-Chart-Container">
            {!bintype && selectedBin && selectedBin.is_garfill && (<FillLevelChart selectedBin={selectedBin} />) }
            {bintype && selectedBin && selectedBin.is_weight_scale && <TotalWeightCount selectedBin={selectedBin} />}
            {bintype && selectedBin && !selectedBin.is_weight_scale && <TotalWasteCount selectedBin={selectedBin}/>}
          </div>
        </div>
      </div>

      {(tier === 'Business' || tier === 'Mid') && (
        <Recommendation/> 
      )}

      <Feedback/>
      <Contact/>
    </div>
  );
};

export default MainDashboard;
