import React, { useState, useEffect, useContext, useRef } from 'react';
import axiosInstance from "api/axios";
import Lottie from 'lottie-react';
import CO2Lottie from "../../../Assets/Lottie/CO2.json"
import { Line } from "react-chartjs-2";
import { Filler } from 'chart.js';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
  LogarithmicScale
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { LanguageContext } from 'context/LanguageContext';
import * as XLSX from 'xlsx';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { CSSTransition } from 'react-transition-group';
import {AiFillInfoCircle, AiOutlineClose} from 'react-icons/ai'
import html2canvas from 'html2canvas';

const RadialHistogram = (props) => {
  const [chartData, setChartData] = useState({});
  const {id,wasteitem, dayvalue, datevalue} = props
  const [dailyvalue, setDailyValue] = useState([])
  const [valuewithout, setValueWithout] = useState([])
  const [height, setHeight] = useState(550);
  const [tier, setTier] = useState('')

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedFormat, setSelectedFormat] = useState('xlsx');

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const chartRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const { language } = useContext(LanguageContext);
  const handleModalClick = () =>{
    setModalIsOpen(false)
  }

  useEffect(()=>{

    let startDate = new Date(datevalue[0].startDate);
    let endDate = new Date(datevalue[0].endDate);
  
    const defaultDateString = 'Thu Jan 01 1970 03:00:00 GMT+0300 (East Africa Time)';
  
    if (startDate.toString().includes('Jan 01 1970')) {
      startDate = null;
    }
    
    if (endDate.toString().includes('Jan 01 1970')) {
      endDate = null;
    }
    
    axiosInstance.get('/co2savings', {params: {
      wasteitem: wasteitem,
      id: id,
      day: dayvalue,
      startDate: startDate,
      endDate:endDate
  }})
    .then(response => {
      console.log(response.data)
      setDailyValue(response.data.savings)
      setValueWithout(response.data.produced)
    })
    .catch(error => {
      console.error(error);
    });
  },[dayvalue, wasteitem, id, datevalue])

  useEffect(() => {
    axiosInstance
        .get("/usertier")
        .then((response) => {
            console.log(response.data.tier)
            setTier(response.data.tier)
        })
        .catch((error) => {
        console.error(error);
        });
    }, []);

  useEffect(() => {
    ChartJS.register(
      BarElement,
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,
      ChartDataLabels,
      LogarithmicScale,
      Filler
    );
  }, []);

  const translateToDutch = (text) => {
    const translations = {
      "Monday": "Maandag",
      "Tuesday": "Dinsdag",
      "Wednesday": "Woensdag",
      "Thursday": "Donderdag",
      "Friday": "Vrijdag",
      "Saturday": "Zaterdag",
      "Sunday": "Zondag",
      "January": "Januari",
      "February": "Februari",
      "March": "Maart",
      "April": "April",
      "May": "Mei",
      "June": "Juni",
      "July": "Juli",
      "August": "Augustus",
      "September": "September",
      "October": "Oktober",
      "November": "November",
      "December": "December",
      "organic": "Biologisch",
      "PMD" : "PMD",
      "paper" :"Papier",
      "residual" : "Resterend",
      "rest" : "Resterend",
    };
    return translations[text] || text;
  }

  const handleExporttoExcel = (option) => {
    if (option === 'xlsx') {
      exportToExcel(dailyvalue, valuewithout);
    } else if (option === 'csv') {
      exportToCSV(dailyvalue,valuewithout);
    } else if (option === 'png') {
      exportChartAsPNG();
    }
  }



  const exportToExcel = (dailyValue, valueWithout) => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
  
    // Prepare the data
    let wastevalue = language === "Dutch" ? translateToDutch(wasteitem) : wasteitem ;
    let streamvalue = language === "Dutch" ? "CO2Besparings" : "Co2Savings"
    
    const data = Object.keys(dailyValue).map(key => {
      let Date = language === "Dutch" ? "Datum": "Date";
      let savingsLabel = language === "Dutch" ? "CO2e-besparing" : "CO2e Savings";
      let c02emissionwithgarby = language === "Dutch" ? "CO2-uitstoot zonder Garby" : "CO2 Emission without Garby";
      let co2emissionwithoutgarby = language === "Dutch" ? "CO2-uitstoot met Garby" : "CO2 Emission with Garby"; 
      let translatedKey = language === "Dutch" ? translateToDutch(key) : key;
      
      return {
        [Date]: translatedKey,
        [co2emissionwithoutgarby] : valueWithout[key],
        [c02emissionwithgarby]: dailyValue[key],
        [savingsLabel]: valueWithout[key] - dailyValue[key]
      };
    });
  
    const ws = XLSX.utils.json_to_sheet(data);
  
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;

    XLSX.writeFile(wb, `${streamvalue}_${wastevalue}_${formattedDate}.xlsx`);
  };


  const exportToCSV = (dailyValue, valueWithout) => {
    let wastevalue = language === "Dutch" ? translateToDutch(wasteitem) : wasteitem;
    let streamvalue = language === "Dutch" ? "CO2Besparings" : "Co2Savings";
  
    // Prepare CSV data
    const csvData = [];
    
    // Create the header row
    const headerRow = ["Date", "CO2 Emission without Garby", "CO2 Emission with Garby", "CO2e Savings"]; // Modify headers as needed
    csvData.push(headerRow);
  
    // Create rows with data
    Object.keys(dailyValue).forEach((key) => {
      let Date = language === "Dutch" ? translateToDutch(key) : key;
      let translatedKey = language === "Dutch" ? translateToDutch(key) : key;
      
      const rowData = [
        Date,
        valueWithout[key],
        dailyValue[key],
        valueWithout[key] - dailyValue[key]
      ]; // Modify data fields as needed
      csvData.push(rowData);
    });
  
    // Convert data to CSV string
    const csvContent = csvData.map((row) => row.join(",")).join("\n");
  
    // Create a Blob and trigger a download
    const blob = new Blob([csvContent], { type: "text/csv" });
    if (window.navigator.msSaveBlob) {
      // For IE
      const date = new Date();
      const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
  
      window.navigator.msSaveBlob(blob, `${streamvalue}_${wastevalue}_${formattedDate}.csv`);
    } else {
      // For modern browsers
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${streamvalue}_${wastevalue}.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  
  const exportChartAsPNG = () => {

    const chartSection = document.getElementById('CO2SavingsChart');
  
    html2canvas(chartSection).then(canvas => {
  
      const image = canvas.toDataURL('image/png');
  
      // Generate filename
      let wastevalue = language === "Dutch" ? translateToDutch(wasteitem) : wasteitem;
      let streamvalue = language === "Dutch" ? "CO2Besparings" : "Co2Savings";
      const date = new Date();
      const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
      const filename = `${streamvalue}_${wastevalue}_${date}.png`;
  
      // Pass filename to save function
      saveDataURLToFile(image, filename);
  
    });
  
  };
  const saveDataURLToFile = (dataURL, filename) => {
  
    // convert data URL to blob
    const blob = dataURLToBlob(dataURL);  
  
    // create object URL from blob 
    const url = URL.createObjectURL(blob);
  
    // create anchor tag
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
  
    // click to download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  };
  
  const dataURLToBlob = (dataURL) => {
    // Convert base64 to raw binary data held in a string
    const byteString = atob(dataURL.split(',')[1]);
  
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  
    const arrayBuffer = new ArrayBuffer(byteString.length);
    
    const ia = new Uint8Array(arrayBuffer);
    
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([arrayBuffer], {type: mimeString});
    return blob;
  
  };
  
  

  const wasteData = {
    labels: language === "Dutch" ? Object.keys(dailyvalue).map(translateToDutch) : Object.keys(dailyvalue),
    datasets: [
      {
        label:  language === "Dutch" ? "CO2-uitstoot zonder Garby" : "CO2 Emission without Garby",
        data: Object.values(valuewithout).map(val => Math.max(0, val)),
        fill: false,
        borderColor: "#1A1A1A",
        backgroundColor: "rgba(0,0,0,1)",
        lineTension: 0.5,
        borderWidth: 1,
      },
      {
        label: language === "Dutch" ? "CO2-uitstoot met Garby" : "CO2 Emission with Garby",
        data: Object.values(dailyvalue).map(val => Math.max(0, val)),
        yAxisID: 'y1',
        borderColor: "gray",
        backgroundColor: "rgba(227,227,227)",
        fill: true,
        lineTension: 0.5,
        borderWidth: 1.6,
      },
      {
        label: language === "Dutch" ? "CO2e-besparing":'CO2e Savings',
        data: Object.values(valuewithout).map(val => Math.max(0, val)),
        fill: true,
        borderColor: "green",
        backgroundColor: "rgba(82,255,82,0.8)",
        lineTension: 0.5,
        borderWidth: 1.6,
      },
    ]
  };
  


  const config = {
    type: 'line',
    data: wasteData,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      layout: {
    padding: {
      top: 20
    }
  },
      stacked: false,
      plugins: {
        title: {
          display: true,
          position: 'left',
          text: language ==="Dutch" ? "CO2e-besparings grafiek" :'CO2e Savings Chart'
        },
        legend: {
          labels: {
            boxHeight: 2
          },
          position: 'bottom',

        },
        datalabels: {
          display: false,
          color: "black",
          align: 'top',
          font: {
            weight: 'bold'
          },
          // formatter: function(value, context) {
          //     return value.y;
          //  
          // }
          
        }
      },
      scales: {
        x:{
          ticks: {
            beginAtZero: true,
            color: "black",
          }
        },
        y1: {
          type: 'linear',
          display: true,
        //   position: 'right',
          min: 0,
          title: {
            display: true,
            text: 'kg'
          },
        //   // grid line settings
          grid: {
            drawOnChartArea: false, 
          },
        },
        y: {
          type: 'linear',
          display: false,
          position: 'left',

        },
        
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            let init_val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            if (init_val === 0) {
              return `${label}: 0`; 
            } 
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            if (label === 'CO2 Savings') {
              let difference = value - data.datasets[0].data[tooltipItem.index];
              return `${label}: ${difference}`;
            } else {
              return `${label}: ${value}`;
            }
          }
        }
      }
    },
  };
  

  return (
    <div>
      
      {tier === 'Business' && ( <div className=''>
              <div className="flex  text-center">
                        <AiFillInfoCircle
                        className="h-5 w-5 text-plaex-bar hover:text-green-500 cursor-pointer mr-2" 
                        onClick={() => setModalIsOpen(!modalIsOpen)}
                      />

                        <div className="relative">
                  <div>
                    <button className="w-6 h-6 text-black underline hover:text-green-500  cursor-pointer" onClick={toggleDropdown}>
                      {language === "Dutch" ? "Exporteren" : "Export"}
                    </button>
                    {isDropdownOpen && (
                      <CSSTransition
                        in={isDropdownOpen}
                        timeout={200}
                        classNames="slide"
                        unmountOnExit
                      >
                        <div className="absolute flex flex-row left-16 -top-2 bg-white border border-gray-300 p-1 rounded shadow">
                          <button onClick={() => handleExporttoExcel('xlsx')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            XLSX
                          </button>
                          <button onClick={() => handleExporttoExcel('csv')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            CSV
                          </button>
                          <button onClick={() => handleExporttoExcel('png')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            PNG
                          </button>
                        </div>

                      </CSSTransition>
                    )}
                  </div>
                </div>
                          
                        </div>
                      </div>
              )}

      <ReactTooltip id="co2savings"/>
       <div className='h-96 mt-10' id='CO2SavingsChart'>
        <Line
            data={wasteData}
            options={config.options}
            ref={chartRef} 
          />
       </div>
       {modalIsOpen && (
  <>
    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 pb-10 z-50 w-full sm:max-w-md">
        <button onClick={handleModalClick} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
        <div>
        The CO2 Savings chart provides an interactive visualization of your CO2 saved by using Garby. The black line represents projected carbon dioxide emissions from waste without using Gaby(Bin). The green area shows actual CO2 savings achieved by using our bins and the gray portion shows CO2 production while using our bins.
        You can filter the chart by waste type and date range to analyze trends and identify opportunities for further lowering your carbon footprint. Hovering over any data point displays the exact savings figures for that time period.
        The stream selector in the top left corner allows toggling between different waste categories to compare savings across various material types. You may export the filtered dataset as a CSV or Excel file for additional analysis.
        </div>

      </div>
    </div>
  </>
)}
    </div>

  );
  
};

export default RadialHistogram;
