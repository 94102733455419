import { useContext } from "react";
import logo from "Assets/svg/plaexLogo.svg";
import whitelogo from "Assets/svg/plaexLogowhite.svg";
import English from "Assets/images/English.png"
import Dutch from "Assets/images/Dutch.png"
import axiosInstance from "api/axios";
import React, { useEffect, useState } from "react";
import {BiSolidChevronDown} from 'react-icons/bi'
import { LanguageContext } from "context/LanguageContext";

const Navbar = (props) => {
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [userLogo, setUserLogo] = useState("");
  const [theme, setTheme] = useState(localStorage.theme);
  const [parentState, setParentState] = useState(theme);
  const [langimage, setLangImage] = useState(localStorage.getItem('langimage') || English);
  const [displaydropdown, setDisplayDropDown] = useState(false)
  const [languagepreference, setLanguagePreference] = useState("English")

  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    localStorage.setItem('langimage', langimage);
  }, [langimage]);

  useEffect(() => {
    axiosInstance
      .get("")
      .then((response) => {
        setEmail(response.data.email);
        setCompany(response.data.company);
        setUserLogo(response.data.user_profile);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/get_bins")
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const binObj = response.data[i];
          if (binObj.is_garfill === false) {
            let element = document.querySelector("#logout-btn");
            element.style.display = "none";
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDropDownChange  = ()=>{
    setDisplayDropDown(!displaydropdown)
  }

  const handleLogout = () => {
    try {
      axiosInstance.post("/user/logout", {
        withCredentials: true,
      });
      localStorage.removeItem("loggedin");
      window.location.href = "/login";
    } catch (e) {
      console.log("logout not working", e);
    }
  };

  const handleLanguageChange = (event) => {
    const language = event.target.getAttribute('value')
    if (language == "English"){
      setLangImage(English)
    }else{
      setLangImage(Dutch)
    }
    setLanguagePreference(language)
    setLanguage(language)
  }

  return (
    <div className="fixed top-0 z-40 w-full px-4 h-24 py-4 bg-white dark:bg-plaex-dark dark:text-white">
      <nav className="px-3 py-2 lg:px-24 lg:pl-6 ">
        <div className="flex justify-between">
          <div className="flex items-center justify-start">
            <a href="/" className="flex w-16 ml-2 md:mr-24">
              <img src={logo} alt="logo" class="block dark:hidden w-20 h-11" />

              <img
                src={whitelogo}
                alt="logo"
                class="hidden dark:block w-20 h-11"
              />
            </a>

            <div className="relative left-0 z-50 bg-white" onClick={handleDropDownChange} >
              <button className="flex flex-row items-center justify-center gap-x-1 p-2">
                <img src={langimage} className="w-6 h-6"/>
                <BiSolidChevronDown/>
              </button>

              {displaydropdown && <ul className="absolute top-[100%] z-50 bg-white shadow-lg  rounded-lg p-2 pr-2">
                <li className="flex items-center justify-start gap-x-1 text-[13px] hover:bg-plaex-light-green p-2 pr-16 rounded-lg cursor-pointer" value="English" onClick={handleLanguageChange}><img src={English} className="w-6 h-6"/>English</li>
                <li className="flex items-center justify-start gap-x-1 text-[13px] hover:bg-plaex-light-green p-2 pr-16 rounded-lg cursor-pointer" value="Dutch" onClick={handleLanguageChange}><img src={Dutch} className="w-6 h-6"/>Dutch</li>
              </ul>}
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            <div
              onClick={() => {
                props.handleClick(parentState);
              }}
            >
              {/** <Switcher handleClick={(e) => setParentState(e)} />*/}
            </div>

            {/* <div>
            <select onChange={handleDropDownChange} className="outline-none">
                <option value="English"><img src={English}/></option>
                <option value="Dutch"><img src={Dutch}/></option>
            </select>
            </div> */}



            <img
              alt="User"
              src={userLogo}
              className="w-12 h-12 rounded-full  object-cover "
            />
            <div>
              <p className="ml-2 hidden text-left text-xs sm:block">
                <strong className="block font-medium">{company}</strong>

                <span className="text-gray-500"> {email} </span>
              </p>
            </div>

            <button
              onClick={handleLogout}
              className=" text-md hover:underline decoration-green-600 underline-offset-8 decoration-4 "
              id="logout-btn"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
